import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: { name: "check.authentication" },
    },
    {
      path: "/authenticate",
      name: "check.authentication",
      component: () => import("@/view/pages/Authentication"),
    },
    {
      path: "/admin",
      name: "admin",
      redirect: { name: "admin.dashboard" },
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "dashboard",
          name: "admin.dashboard",
          component: () => import("@/view/pages/Dashboard"),
          meta: {
            requiresAuth: true,
            menuActive: "dashboard",
            title: "Dashboard",
          },
        },
        {
          path: "profile",
          name: "admin.profile",
          redirect: { name: "admin.profile.basic" },
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "basic",
              name: "admin.profile.basic",
              component: () => import("@/view/pages/profile/AdminBasic.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "profile",
                title: "Profile",
              },
            },
          ],
        },
        {
          path: "users",
          name: "admin.profile.users",
          component: () => import("@/view/pages/profile/Users.vue"),
          meta: {
            requiresAuth: true,
            title: "User",
          },
        },
        {
          path: "users/permission/:id",
          name: "admin.profile.userpermission",
          component: () => import("@/view/pages/profile/UserPermission.vue"),
          meta: {
            requiresAuth: true,
            title: "Users Permission",
          },
        },
        {
          path: "customer",
          name: "admin.customer",
          component: () => import("@/view/pages/customer/Customer"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "customer",
            title: "Customer",
          },
        },
        {
          path: "customer/create",
          name: "admin.customer.create",
          component: () => import("@/view/pages/customer/Create-Customer"),
          meta: {
            requiresAuth: true,
            menuActive: "customer",
            title: "Create | Customer",
          },
        },
        {
          path: "customer/detail/:id",
          name: "admin.customer.detail",
          component: () => import("@/view/pages/customer/Detail-Customer"),
          meta: {
            requiresAuth: true,
            menuActive: "customer",
            title: "Detail | Customer",
          },
        },
        {
          path: "user",
          name: "admin.user",
          component: () => import("@/view/pages/user/User"),
          meta: {
            requiresAuth: true,
            menuActive: "user",
            title: "User",
          },
        },
        {
          path: "user/create",
          name: "admin.user.create",
          component: () => import("@/view/pages/user/Create-User"),
          meta: {
            requiresAuth: true,
            menuActive: "user",
            title: "Create | User",
          },
        },
        {
          path: "user/detail/:id",
          name: "admin.user.detail",
          component: () => import("@/view/pages/user/Detail-User"),
          meta: {
            requiresAuth: true,
            menuActive: "user",
            title: "Detail | User",
          },
        },
        {
          path: "service",
          name: "admin.service",
          component: () => import("@/view/pages/service/Service"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "service",
            title: "Service",
          },
        },
        {
          path: "service/create",
          name: "admin.service.create",
          component: () => import("@/view/pages/service/Create-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Create | Service",
          },
        },
        {
          path: "service/update/:id",
          name: "admin.service.update",
          component: () => import("@/view/pages/service/Create-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Update | Service",
          },
        },
        {
          path: "service/detail/:id",
          name: "admin.service.detail",
          component: () => import("@/view/pages/service/Detail-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Detail | Service",
          },
        },
        {
          path: "job/create",
          name: "admin.job.create",
          component: () => import("@/view/pages/job/Create-Job"),
          meta: {
            requiresAuth: true,
            menuActive: "visit",
            title: "Create | Visit",
          },
        },
        {
          path: "visit",
          name: "admin.visit",
          component: () => import("@/view/pages/visit/Visit"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "visit",
            title: "Visit",
          },
        },
        {
          path: "visit/detail/:id",
          name: "admin.visit.detail",
          component: () => import("@/view/pages/visit/Visit-Detail"),
          meta: {
            requiresAuth: true,
            menuActive: "visit",
            title: "Visit Detail",
          },
        },
        {
          path: "engineer",
          name: "admin.engineer",
          component: () => import("@/view/pages/engineer/Engineer"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "engineer",
            title: "Staff",
          },
        },
        {
          path: "engineer/create",
          name: "admin.engineer.create",
          component: () => import("@/view/pages/engineer/Create-Engineer"),
          meta: {
            requiresAuth: true,
            menuActive: "engineer",
            title: "Create | Engineer",
          },
        },
        {
          path: "engineer/update/:id",
          name: "admin.engineer.update",
          component: () => import("@/view/pages/engineer/Create-Engineer"),
          meta: {
            requiresAuth: true,
            menuActive: "engineer",
            title: "Update | Engineer",
          },
        },
        {
          path: "engineer/detail/:id",
          name: "admin.engineer.detail",
          component: () => import("@/view/pages/engineer/Detail-Engineer"),
          meta: {
            requiresAuth: true,
            menuActive: "engineer",
            title: "Detail | Engineer",
          },
        },
      ],
    },
    {
      path: "/admin/login",
      name: "admin.login",
      component: () => import("@/view/pages/auth/Login"),
      meta: { requiresAuth: false, title: "Admin | Login" },
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1"),
      meta: { requiresAuth: false, title: "Page Not Found" },
    },
  ],
});
